import React, { Component } from "react";
import { Modal, Select } from "antd";
import finalArt from "../../../assets/images/180.png";
import marketLogo from "../../../assets/images/180M-logo.png";
import marketLogoIcon from "../../../assets/images/180markets.png";
import HomeRegister from "../../../components/forms/Home/HomeRegisterContainer";
import { Link } from "react-router-dom";
import ClosedDeals from "./ClosedDeals/closedDeals";
import EaseImg from "../../../assets/images/Group 7841.png";
import FairImg from "../../../assets/images/Group 7843.png";
import AnalyseImg from "../../../assets/images/Group 7844.png";
import HomeImg from "../../../assets/images/Group-new.png";
import HomeVideo from "../../../assets/videos/home.mp4";
import HomeVideoImg from "../../../assets/images/big-video-image.jpeg";
import TopFooter from "../../../components/layout/TopFooter";
import InvestorForm from "../../forms/InvestorForm";
import auth from "../../../services/authService";
import { toast } from "../../common/Toast";
import { connect } from "react-redux";
import countryActions from "../../../redux/actions/countryActions";
import ButtonLoader from "../../common/ButtonLoader";
import { Button } from "react-bootstrap";

const { Option } = Select;

class Home extends Component {
  constructor(props) {
    super(props);
    this.brokerRef = React.createRef();
    this.videoRef = React.createRef();

    this.state = {
      selectedTab: "Investors",
      isModalVisible: false,
    };
  }

  componentDidMount() {
    const { fetchCountries } = this.props;
    fetchCountries();
  }

  setSelectedTab = (selected) => {
    this.setState({
      selectedTab: selected,
    });
  };

  brokerRegisterHandler = (values) => {
    const { history } = this.props;
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.props.register({ ...values, role: "Broker" }, history);
  };

  openVideo = () => {
    this.setState(
      {
        isModalVisible: true,
      },
      () => {
        if (this.videoRef && this.videoRef.current) {
          this.videoRef.current.play();
        }
      }
    );
  };
  cancelHandler = () => {
    this.videoRef.current.pause();
    this.setState({
      isModalVisible: false,
    });
    // this.videoRef.current.stop();
  };

  investorRegisterHandler = (values) => {
    this.setState({
      visible: true,
      phone: values.phone,
      formData: { ...values, role: "Investor" },
    });
  };

  handleOk = () => {
    this.setConfirmLoading(true);

    const { countries } = this.props;

    let phone = this.state.phone;
    let country_id = this.state.country_id;
    let country = countries.find((country) => country.id === country_id);
    let countryCode = country ? `+${country.phonecode}` : "";
    let errors = {};
    if (!phone || phone === "") {
      errors.phone = "Phone number is required";
    } else {
      if (phone.length !== 10) {
        errors.phone = "Invalid phone number";
      }
    }
    if (!country_id || country_id === "") {
      errors.country_id = "Country is required";
    }
    let code = this.state.code;
    if (phone && phone.length && phone[0] === "0") {
      phone = phone.replace(/0/, countryCode);
    } else {
      phone = countryCode + phone;
    }
    this.setState({
      errors,
    });
    if (this.state.verificationCode) {
      if (!code || code === "") {
        errors.code = "Verification Code is required";
        this.setState({
          errors,
        });
      }
      if (Object.keys(errors).length) {
        this.setConfirmLoading(false);
        return;
      }
      auth
        .verifyCode({ phone, code })
        .then(async (data) => {
          if (data.verified) {
            const { history } = this.props;
            this.props.register(this.state.formData, history);
            this.setVisible(false);
          } else {
            await toast.error("Verification failed: Try again");
          }
          this.setConfirmLoading(false);
        })
        .catch((error) => {
          toast.error("Server Error");
          this.setConfirmLoading(false);
        });
    } else {
      if (Object.keys(errors).length) {
        this.setConfirmLoading(false);
        return;
      }
      auth
        .verifyPhone({ phone })
        .then(async (data) => {
          if (data.success) {
            this.setState({
              verificationCode: true,
            });
            await toast.success("Verification Code Sent");
          } else {
            await toast.error("Failed to send verification code");
          }
          this.setConfirmLoading(false);
        })
        .catch((error) => {
          toast.error("Server Error");
          this.setConfirmLoading(false);
        });
    }
  };

  render() {
    return (
      <main className="page-main home-page pt-0">
        <div className="container-fluid home_bg ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 p-4">
                <div className="row">
                  <div className="col-lg-12 branding-desc">
                    <h3 class="hero_heading">We put <br /> investors first</h3>
                    <h4 class="pt-4 hero_heading_2">Get FREE access to ASX capital raises within minutes</h4>
                    <p class="pt-4">In the past, share placements were only reserved for investment funds. WIth 180 Markets, you have the power, with access to more deals than any other platform, all in one place</p>
                    <p class="pt-4">Sign up in as little as 30 seconds and start investing today</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5" id="investorBroker__register">
                <div className="nav-tab-bg">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item col p-0">
                      <a
                        className="nav-link active text-center"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="false"
                      >
                        INVESTORS
                      </a>
                    </li>
                    <li className="nav-item col p-0">
                      <a
                        className="nav-link text-center"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                      >
                        BROKERS
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <InvestorForm
                        onSubmit={this.investorRegisterHandler}
                        initialValues={{ experienced: 0 }}
                        countries={this.props.countries}
                        home={true}
                        {...this.props}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <HomeRegister role={`Broker`} {...this.props} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        <div
          className="container-fluid"
          style={{
            padding: "110px 0 110px 0",
            backgroundColor: '#F3F7FF'
          }}
        >
          <h3 class="hero_heading text-center pb-5">Find out why it's never been easier to invest</h3>
          <div className="d-flex justify-content-center">
            <a href="javascript:void(0);" onClick={this.openVideo}>
              <img src={HomeVideoImg} className="img-fluid" width="500" />
            </a>

            <Modal
              visible={this.state.isModalVisible}
              footer={null}
              onCancel={this.cancelHandler}
              width={850}
              transitionName=""
            >
              <video
                ref={this.videoRef}
                src={HomeVideo}
                width="800"
                height="500"
                controls="controls"
                autoPlay={true}
              />
            </Modal>
          </div>
        </div>
        <div>
          <div
            className="container-fluid"
            style={{
              padding: "110px 0 0 0",
            }}
          >
            <h3 className="hero_heading text-center pb-5">
              Previous Placements
            </h3>

            <div
              id="multi-item-example"
              className="carousel slide carousel-multi-item"
              data-ride="carousel"
            >
              <div className="closed-deals carousel-inner" role="listbox">
                <div className="col-12 carousel-item active">
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        padding: "130px 50px 130px 50px !important",
                      }}
                    >
                      <ClosedDeals />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main_bg">
          <div className="container">
            <div className="nav-tab-bg how-it-works" id="how-it-works">
              <h3 className="hero_heading text-center how_it_work">
                How It Works
              </h3>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item col p-0">
                  <a
                    className="nav-link active text-center"
                    id="home-tab"
                    data-toggle="tab"
                    href="#investors"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <span className="tab__transition">INVESTORS</span>
                  </a>
                </li>
          
                <li className="nav-item col p-0">
                  <a
                    className="nav-link text-center"
                    id="companies-tab"
                    data-toggle="tab"
                    href="#companies"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <span className="tab__transition">LISTED COMPANIES</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="investors"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div
                    className="row"
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <div className="col-lg-5 offset-1 padd bgtext">
                      <h3 className="hiw_heading">
                        <a
                          href="/auth/register/investor"
                          style={{ color: "inherit" }}
                        >
                          1. Create an Account
                        </a>
                      </h3>
                      <p className="hiw_p">
                        Upload one ID document, your entity details and a
                        Sophisticated Investor form signed by your accountant.
                      </p>
                      <Link to="/register" style={{ position: 'relative', zIndex: 999 }}>
                        <button
                        className="btn btn-primary btn-medium mt-5"
                        >
                        Create An Account 
                      </button></Link>
                    </div>
                    <div className="col-lg-5 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Verify</h3>
                      <p className="hiw_p">
                        We will verify your account within minutes so you can
                        bid on our next deal.
                      </p>
                    </div>
                  </div>
                </div>
         
                <div
                  className="tab-pane fade"
                  id="companies"
                  role="tabpanel"
                  aria-labelledby="companies-tab"
                >
                  <div className="row">
                    <div className="col-lg-3 offset-1 padd bgtext">
                      <h3 className="hiw_heading">1. 180 Advantage</h3>
                      <p className="hiw_p">
                        Raise money cheaper and faster with 180 Markets.
                      </p>
                    </div>
                    <div className="col-lg-3 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Access</h3>
                      <p className="hiw_p">
                        As long as your company is listed on the ASX, our
                        investors will have access to your raise.
                      </p>
                    </div>
                    <div className="col-lg-3 offset-1 padd bgtext_3">
                      <h3 className="hiw_heading">3. Upload Information</h3>
                      <p className="hiw_p">
                        Upload a term sheet and some basic company information
                        and watch the bids come in.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mt-12em">
              <div className="col-lg-4 padd_new new__padding text-center">
                <img src={EaseImg} className="logo__img" />
                <h3 className="heading_new">Ease of one account</h3>
                <p className="p_txt">
                  In short, you’re in control. You hold your securities and
                  funds in your existing brokerage account. All we do is use
                  your brokerage firm’s transfer details and your shares can go
                  onto your HIN or SRN straight away. Access the world of share
                  placements without the need to open multiple accounts. Your
                  days of nightmare paperwork are over.
                </p>
              </div>
              <div className="col-lg-4 padd_new  new__padding text-center">
                <img src={FairImg} className="logo__img" />
                <h3 className="heading_new">Fairer allocations</h3>
                <p className="p_txt">
                  180 Markets flips the power around 180 degrees. In the past,
                  share placements were only reserved for investment funds. With
                  180 Markets, this is no longer the case. We give you the power
                  by providing you access to our partners' placements at your
                  fingertips.
                </p>
              </div>
              <div className="col-lg-4 padd_new new__padding text-center">
                <img src={AnalyseImg} className="logo__img" />
                <h3 className="heading_new">In depth analysis</h3>
                <p className="p_txt">
                  180 Markets gives you greater insights into your investment
                  performance: we make it simple to see your P&L and analyse
                  your trade history. Not ready to jump in yet? No problem. Just
                  register with your name and email address and start viewing
                  and learning about live capital raisings today. Whenever
                  you’re comfortable, you can upgrade to a live account.
                </p>
              </div>
            </div>
          </div>
        </div>

        <TopFooter />
      </main>
    );
  }
}

const { fetchCountries } = countryActions;

const mapStateToProps = (state) => ({
  optedType: state.register.optedType,
  regState: state.register,
  countries: state.countries.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: (payload) => fetchCountries(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
