import React, { Component, useEffect, useState } from "react";
import Navigation from "../navigation/Navigation";
import {
  BrandLogo,
  LiveDealsButton,
  CornerstoneButton,
  MenuToggle,
  Avator,
  StockTipButton,
} from "../common";
import { showUserMenu } from "../../redux/actions/appActions";
import UserMenu from "../common/UserMenu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../redux/actions/authActions";
import $ from "jquery";
import { PlayCircleOutlined, PlayCircleTwoTone } from "@ant-design/icons";
import { Icon } from "semantic-ui-react";
import liveActions from "../../redux/actions/liveActions";
var moment = require("moment");

const UserHeader = ({ currentUser, showUserMenu, toggleMobileMenu }) => {
  const inInvestor = currentUser.role
    ? currentUser.role.some((role) => role.title === "Investor")
    : "";
  return (
    <>
      <div
        className={`md-header-desktop clearfix d-none d-lg-block ${
          currentUser && "page-header-loggedin"
        }`}
        style={{
          paddingLeft: "155px",
          paddingBottom: "10px",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: "4px",
          }}
        >
          <BrandLogo />
          <div
            className="main-nav-desktop"
            style={{
              width: "945px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: "0",
              marginRight: "0",
            }}
          >
            <Navigation handleClick={toggleMobileMenu} />

            <div className="account">
              {/* <StockTipButton /> */}
              <CornerstoneButton />
              <LiveDealsButton />

              <Avator
                showUserMenu={showUserMenu}
                name={currentUser.full_name}
                url={currentUser.photo_url || currentUser.logo_url}
              />
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <UserMenu showUserMenu={showUserMenu} currentUser={currentUser} />
          </div>
        </div>
      </div>
    </>
  );
};

const LiveNow = ({ liveStream }) => {
  const [time, setTime] = useState(null);
  useEffect(() => {
    setInterval(() => {
      var duration = moment.duration(
        moment(
          `${liveStream.date} ${liveStream.time}`,
          "DD/MM/YYYY H:mm:ss"
        ).diff(moment())
      );
      // duration in hours
      var hours = parseInt(duration.asHours());
      // duration in minutes
      var minutes = parseInt(duration.asMinutes()) % 60;
      let text = `in `;
      if (hours > 1) {
        text += `${hours} hours`;
      } else {
        text += `${hours} hour`;
      }
      if (minutes > 0) {
        text += ` and `;
        if (minutes > 1) {
          text += `${minutes} minutes`;
        } else {
          text += `${minutes} minute`;
        }
      }
      setTime(`${text}`);
    }, 1000);
  }, []);
  return (
    <>
      <div
        className={``}
        style={{
          backgroundColor: "#001833",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className=""
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingRight: "0",
              marginRight: "0",
              color: "#00FEC3",
              fontSize: "18px",
              alignItems: "center",
              height: "50px",
              fontWeight: "400",
            }}
          >
            <Link
              style={{
                color: "#00FEC3",
                fontSize: "18px",
              }}
              to={"/live-now"}
              className="nounderline"
            >
              {!liveStream.live_now && liveStream.display_banner && (
                <div className="text-uppercase">
                  LIVE STREAM STARTING {time}
                </div>
              )}
              {liveStream.live_now && (
                <div>
                  WE ARE LIVE - JOIN NOW! &nbsp;&nbsp;
                  <Icon
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      fontSize: "22px",
                    }}
                    name="play circle outline"
                  />
                </div>
              )}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const Notification = ({ currentNotification }) => {
  return (
    <>
      <div
        className={``}
        style={{
          backgroundColor: "#001833",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="account"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingRight: "0",
              marginRight: "0",
              color: "#00FEC3",
              fontSize: "18px",
              alignItems: "center",
              height: "50px",
              fontWeight: "400",
            }}
          >
            {currentNotification && (
              <div className="text-capitalize">
                {currentNotification.message} &nbsp;&nbsp;
                {currentNotification.button_link &&
                  currentNotification.button_label && (
                    <a
                      href={currentNotification.button_link}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="btn btn-outline-primary live_btn"
                    >
                      {currentNotification.button_label}
                    </a>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileMenu: false,
    };
  }

  componentDidMount() {
    const { getCurrent, getNotification } = this.props;
    getCurrent();
    getNotification();

    setInterval(() => {
      getCurrent();
      getNotification();
    }, 30000);

    // $(".md-header-mobile .toggle-menu").click(function () {
    //   $(this).parents(".md-header-mobile").toggleClass("active");
    //   $("body").toggleClass("navbar-open");
    // });

    // $(document).mouseup(function (e) {
    //   var container = $(".md-header-mobile");
    //   switch (e.which) {
    //     case 1:
    //       if (!container.is(e.target) && container.has(e.target).length === 0) {
    //         if ($("body.navbar-open").length > 0) {
    //           $("body").toggleClass("navbar-open");
    //           $(".md-header-mobile").toggleClass("active");
    //         }
    //       }
    //   }
    // });
    /* jQuery for desktop mobile
    -----------------------------------------------------*/
    $(".md-header-desktop .toggle-search").click(function () {
      $(this).parents(".search").toggleClass("active");
      $(this).parents(".search").find(".form-control").focus();
    });
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => {
      return {
        showMobileMenu: !prevState.showMobileMenu,
      };
    });
  };

  render() {
    let { currentUser, showUserMenu, liveStream, currentNotification } =
      this.props;

    return (
      <>
        <header className="page-header clearfix">
          {liveStream && <LiveNow liveStream={liveStream} />}
          {currentNotification && (
            <Notification currentNotification={currentNotification} />
          )}
          {currentUser.id ? (
            <>
              <UserHeader
                currentUser={currentUser}
                showUserMenu={showUserMenu}
                showMobileMenu-={this.state.showMobileMenu}
                toggleMobileMenu={this.toggleMobileMenu}
              />
            </>
          ) : (
            <div className="md-header-desktop clearfix d-none d-lg-block">
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  paddingBottom: "4px",
                  justifyContent: "space-between",
                }}
              >
                <BrandLogo />

                <div className="main-nav-desktop">
                  <Navigation handleClick={this.toggleMobileMenu} />
                  {/*<ToggleSearch />*/}
                  <div className="account">
                    <LiveDealsButton />
                  </div>
                  <div className="account">{/* <CornerstoneButton /> */}</div>
                </div>
              </div>
            </div>
          )}

          <div className="md-header-mobile d-block d-lg-none">
            <div className="top-navtigation">
              <div className="container">
                <div className="ctn-inner">
                  <BrandLogo />
                  <MenuToggle
                    showMobileMenu={this.state.showMobileMenu}
                    handleClick={this.toggleMobileMenu}
                    changeSidebar={this.props.changeSidebar}
                    visible={this.props.visible}
                  />
                </div>
              </div>
            </div>
            {/* <div className="main-nav-mobile">
              <div className="container">
                <ul className="clearfix">
                  <li>
                    <a href="#">For Brokers</a>
                  </li>
                  <li>
                    <a href="#">For Investors</a>
                  </li>
                  <li>
                    <a href="#">How it Works</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                  <li>
                    <a href="#">Login</a>
                  </li>
                </ul>
                <div className="smd-search">
                  <form>
                    <div className="field-input">
                      <input type="text" className="form-control" />
                      <button className="btn-search"></button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </header>
      </>
    );
  }
}
const { getCurrentLiveStream, getCurrentNotification } = liveActions;

const mapDispatchToProps = (dispatch) => ({
  showUserMenu: () => dispatch(showUserMenu),
  logoutUser: () => dispatch(logoutUser),
  getCurrent: () => getCurrentLiveStream(dispatch),
  getNotification: () => getCurrentNotification(dispatch),
});

const mapStateToProps = (state) => {
  return {
    liveStream: state.liveStreams.current,
    currentNotification: state.liveStreams.currentNotification,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
