import React, { Component } from "react";
import { Modal } from "antd";
import EaseImg from "../../../assets/images/Group 7841.png";
import FairImg from "../../../assets/images/Group 7843.png";
import AnalyseImg from "../../../assets/images/Group 7844.png";
import HomeVideo from "../../../assets/videos/home.mp4";
import HomeVideoImg from "../../../assets/images/big-video-image.jpeg";

export default class HowItWork extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      isModalVisible: false,
    };
  }

  openVideo = () => {
    this.setState(
      {
        isModalVisible: true,
      },
      () => {
        if (this.videoRef && this.videoRef.current) {
          this.videoRef.current.play();
        }
      }
    );
  };

  cancelHandler = () => {
    this.videoRef.current.pause();

    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    return (
      <main className="page-main home-page pt-5">
        <div class="main_bg">
          <div className="container">
            <div className="nav-tab-bg how-it-works" id="how-it-works">
              <h3 className="hero_hedading text-center how_it_work">
                How It Works
              </h3>
              <div
                style={{
                  padding: "0px 0px 100px 0",
                }}
              >
                <div className="d-flex justify-content-center">
                  <>
                    <a href="javascript:void(0);" onClick={this.openVideo}>
                      <img
                        src={HomeVideoImg}
                        className="img-fluid"
                        width="500"
                      />
                    </a>

                    <Modal
                      visible={this.state.isModalVisible}
                      footer={null}
                      onCancel={this.cancelHandler}
                      width={850}
                    >
                      <video
                        ref={this.videoRef}
                        src={HomeVideo}
                        width="800"
                        height="500"
                        controls="controls"
                        autoPlay={true}
                      />
                    </Modal>
                  </>
                </div>
              </div>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item col p-0">
                  <a
                    className="nav-link active text-center"
                    id="home-tab"
                    data-toggle="tab"
                    href="#investors"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <span className="tab__transition">INVESTORS</span>
                  </a>
                </li>
                <li className="nav-item col p-0">
                  <a
                    className="nav-link text-center"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#brokers"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <span className="tab__transition">BROKERS</span>
                  </a>
                </li>
                <li className="nav-item col p-0">
                  <a
                    className="nav-link text-center"
                    id="companies-tab"
                    data-toggle="tab"
                    href="#companies"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <span className="tab__transition">LISTED COMPANIES</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="investors"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div
                    className="row"
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <div className="col-lg-5 offset-1 padd bgtext">
                      <h3 className="hiw_heading">
                        <a
                          href="/auth/register/investor"
                          style={{ color: "inherit" }}
                        >
                          1. Create an Account
                        </a>
                      </h3>
                      <p className="hiw_p">
                        Upload one ID document, settlement instructions, and a
                        proof of Sophisticated Investor form from your
                        accountant.
                      </p>
                    </div>
                    <div className="col-lg-5 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Verify</h3>
                      <p className="hiw_p">
                        We will verify your account within minutes and you can
                        start investing.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="brokers"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    <div className="col-lg-3 offset-1 padd bgtext">
                      <h3 className="hiw_heading">
                        <a
                          href="/auth/register/broker"
                          style={{ color: "inherit" }}
                        >
                          1. Create an Account
                        </a>
                      </h3>
                      <p className="hiw_p">Quick and easy account creation.</p>
                    </div>
                    <div className="col-lg-3 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Verify</h3>
                      <p className="hiw_p">
                        We will verify your account within minutes.
                      </p>
                    </div>
                    <div className="col-lg-3 offset-1 padd bgtext_3">
                      <h3 className="hiw_heading">3. Upload Investments</h3>
                      <p className="hiw_p">
                        Upload your Capital Raises in a few minutes and see live
                        bids from our Investors on your Portal.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="companies"
                  role="tabpanel"
                  aria-labelledby="companies-tab"
                >
                  <div className="row">
                    <div className="col-lg-3 offset-1 padd bgtext">
                      <h3 className="hiw_heading">1. 180 Advantage</h3>
                      <p className="hiw_p">
                        Raise money cheaper and faster with 180 Markets.
                      </p>
                    </div>
                    <div className="col-lg-3 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Access</h3>
                      <p className="hiw_p">
                        As long as your company is listed on the ASX, our
                        investors will have access to your raise.
                      </p>
                    </div>
                    <div className="col-lg-3 offset-1 padd bgtext_3">
                      <h3 className="hiw_heading">3. Upload Information</h3>
                      <p className="hiw_p">
                        Upload a term sheet and some basic company information
                        and watch the bids come in.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mt-12em">
              <div className="col-lg-4 padd_new new__padding text-center">
                <img src={EaseImg} className="logo__img" />
                <h3 className="heading_new">Ease of one account</h3>
                <p className="p_txt">
                  In short, you’re in control. You hold your securities and
                  funds in your existing brokerage account. All we do is use
                  your brokerage firm’s transfer details and your shares can go
                  onto your HIN or SRN straight away. Access the world of share
                  placements without the need to open multiple accounts. Your
                  days of nightmare paperwork are over.
                </p>
              </div>
              <div className="col-lg-4 padd_new  new__padding text-center">
                <img src={FairImg} className="logo__img" />
                <h3 className="heading_new">Fairer allocations</h3>
                <p className="p_txt">
                  180 Markets flips the power around 180 degrees. In the past,
                  share placements were only reserved for investment funds. With
                  180 Markets, this is no longer the case. We give you the power
                  by providing you access to our partners' placements at your
                  fingertips.
                </p>
              </div>
              <div className="col-lg-4 padd_new new__padding text-center">
                <img src={AnalyseImg} className="logo__img" />
                <h3 className="heading_new">In depth analysis</h3>
                <p className="p_txt">
                  180 Markets gives you greater insights into your investment
                  performance: we make it simple to see your P&L and analyse
                  your trade history. Not ready to jump in yet? No problem. Just
                  register with your name and email address and start viewing
                  and learning about live capital raisings today. Whenever
                  you’re comfortable, you can upgrade to a live account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
